import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { Rating } from "primereact/rating";
import { TabMenu } from "primereact/tabmenu";
import { Fieldset } from "primereact/fieldset";
import { Tag } from "primereact/tag";
import InnerImageZoom from "react-inner-image-zoom";
import moment from "moment";
import { status } from "../../../functions/hotmerFunctions";
import CardsContent from "./Cards";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from "axios";
import "../../../styles/customers.scss";
import "./styles.css";

const DialogHotmerDetails = ({ show, setShow }) => {
    const { hotmerDetail } = useSelector((state) => state.hotmersList, shallowEqual);
    // const {Summary:summary,personal_data:personal_data,Stat:stat,finantial_account:finantial_account,Subscription_Price:subscription_Price}=hotmerDetail;
    const closeDialog = () => {
        setShow(false);
    };

    const Hedaer = () => {
        return (
            <div className="p-d-flex p-jc-between p-ai-center p-pt-3 p-pl-3 p-pr-3 p-mb-5" style={{ border: "2px solid #d0d0d0", padding: 5, borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                <h2 className="bold">
                    {hotmerDetail.personal_data.full_name} ({hotmerDetail.personal_data.ds_display_name})
                </h2>
                <Button onClick={() => closeDialog()} icon="pi pi-times" className="p-button-rounded p-button-secondary p-button-text" />
            </div>
        );
    };
    const renderFooter = () => {
        return (
            <Button
                label="OK"
                icon="pi pi-check"
                className={`p-button-info`}
                autoFocus
                onClick={() => {
                    closeDialog();
                }}
            />
        );
    };

    const SummaryCommision = () => {
        return (
            <Fieldset className="p-mb-2" legend="Commision Summary" toggleable>
                {hotmerDetail.commision ? (
                    <div>
                        <span>
                            <b>Comision que cobra Hotmy segun trasacción:</b>
                        </span>
                        <div>
                            {" "}
                            Commision Subscription:{" "}
                            <span>
                                <b>{hotmerDetail.commision.vl_subscription_hotmy}% </b>
                            </span>{" "}
                        </div>
                        <div>
                            {" "}
                            Commision Pack:{" "}
                            <span>
                                <b>{hotmerDetail.commision.vl_pack_hotmy}%</b>
                            </span>{" "}
                        </div>
                        <div>
                            {" "}
                            Commision Tip/Regalo:{" "}
                            <span>
                                <b> {hotmerDetail.commision.vl_tip_hotmy}%</b>
                            </span>{" "}
                        </div>
                        <span>
                            <b>Comision por REFERIDOS del Creador:</b>
                        </span>
                        <div>
                            {" "}
                            Commision Subscription:{" "}
                            <span>
                                <b>{hotmerDetail.commision.vl_subscription_for_referral}% </b>
                            </span>{" "}
                        </div>
                        <div>
                            {" "}
                            Commision Pack:{" "}
                            <span>
                                <b>{hotmerDetail.commision.vl_pack_for_referral}%</b>
                            </span>{" "}
                        </div>
                        <div>
                            {" "}
                            Commision Tip/Regalo:{" "}
                            <span>
                                <b> {hotmerDetail.commision.vl_tip_for_referral}%</b>
                            </span>{" "}
                        </div>
                    </div>
                ) : (
                    <h3>Comisiones No Asignadas, aun no es Hotmer aprobada</h3>
                )}
            </Fieldset>
        );
    };

    const Summary = () => {
        return (
            <>
                <Fieldset className="p-mb-2" legend="Hotmer Summary" toggleable>
                    {hotmerDetail.summary ? (
                        <div className="p-d-flex p-jc-between p-fluid p-formgrid p-grid">
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-qualified p-mb-1">
                                    <span className="pi pi-bell" /> {hotmerDetail.summary.total_subscribers}
                                </b>
                                <b className="customer-badge status-qualified">
                                    <small>Subscribers</small>
                                </b>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-proposal  p-mb-1">
                                    <span className="pi pi-hotmerDetails" /> {hotmerDetail.summary.total_follow}
                                </b>
                                <b className="customer-badge status-proposal">
                                    <small>Followers</small>
                                </b>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-renewal p-mb-1">
                                    <span className="pi pi-image" /> {hotmerDetail.summary.total_image}
                                </b>
                                <b className="customer-badge status-renewal">
                                    <small>Images</small>
                                </b>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-unqualified p-mb-1">
                                    <span className="pi pi-video" /> {hotmerDetail.summary.total_pack}
                                </b>
                                <b className="customer-badge status-unqualified">
                                    <small>Packs</small>
                                </b>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-negotiation p-mb-1">
                                    <span className="pi pi-comments" /> {hotmerDetail.summary.total_comments_received}
                                </b>
                                <b className="customer-badge status-negotiation">
                                    <small>Comments</small>
                                </b>
                            </div>
                            <div className="p-d-flex p-flex-column p-ai-center p-field p-col p-text-center p-ml-5">
                                <b className="customer-badge status-new p-mb-1">
                                    <span className="pi pi-thumbs-up" /> {hotmerDetail.summary.total_likes}
                                </b>
                                <b className="customer-badge status-new">
                                    <small>Likes</small>
                                </b>
                            </div>
                        </div>
                    ) : (
                        <h3>Not found</h3>
                    )}
                </Fieldset>
                {/**SUMARRY/ */}
                <SummaryCommision />
            </>
        );
    };

    const Statistics = () => {
        const { money_available, money_settle, money_extraction, money_accumulated } = hotmerDetail.finantial_account;
        // console.log(hotmerDetail.finantial_account);
        // console.log(hotmerDetail.finantial_account.money_accumulated);
        return (
            <>
                <Fieldset legend="Resumen">
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.local.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Local</span>
                            </div>
                            <span style={{ color: "green" }}> Total Facturado:</span>{" "}
                            <span style={{ fontWeight: "bold" }}>
                                ${money_accumulated.local.value} ({money_accumulated.local.currency})
                            </span>
                            <p></p>
                            <span style={{ color: "red" }}> Total Extracciones:</span>{" "}
                            <span style={{ fontWeight: "bold" }}>
                                - ${money_extraction.local.value} ({money_extraction.local.currency})
                            </span>
                        </div>
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.internacional.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Internacional</span>
                            </div>
                            <span style={{ color: "green" }}> Total Facturado:</span> ${money_accumulated.internacional.value} ({money_extraction.internacional.currency})<p></p>
                            <span style={{ color: "red" }}> Total Extracciones:</span>{" "}
                            <span style={{ fontWeight: "bold" }}>
                                - ${money_extraction.internacional.value} ({money_extraction.internacional.currency})
                            </span>
                        </div>
                    </div>
                </Fieldset>
                <p></p>
                <Fieldset className="p-mb-2" legend="Money Available :  Dinero Disponible" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.local.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Local</span>
                            </div>
                            <p>
                                <b>Currency: </b>${money_available.local.value} ({money_available.local.currency}){" "}
                            </p>
                            <p>
                                <b>Conversion: </b>$1 ({money_available.local.currency}) = ${money_available.local.vl_conversion_rate} (USD)
                            </p>
                        </div>
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.internacional.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Internacional</span>
                            </div>
                            <p>
                                <b>Currency: </b>${money_available.internacional.value} ({money_available.internacional.currency}){" "}
                            </p>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset legend="Money Settle: Dinero a Liquidar" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.local.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Local</span>
                            </div>
                            <p>
                                <b>Currency: </b>${money_settle.local.value} ({money_settle.local.currency}){" "}
                            </p>
                        </div>
                        <div className="p-col">
                            <div className="p-d-flex p-ai-center p-mb-4">
                                <span className={`flag flag-${money_available.internacional.ds_flag_code.toLowerCase()}`} style={{ width: 30, height: 20 }} />
                                <span style={{ marginLeft: ".5em", verticalAlign: "middle", fontSize: 18, fontWeight: "bold" }}>Internacional</span>
                            </div>
                            <p>
                                <b>Currency: </b>${money_settle.internacional.value} ({money_settle.internacional.currency}){" "}
                            </p>
                        </div>
                    </div>
                </Fieldset>
                <p></p>
            </>
        );
    };

    const Stat = () => {
        return (
            <>
                <Fieldset className="p-mb-2" legend="Stat" toggleable>
                    {hotmerDetail.stat ? (
                        <div className="p-d-flex">
                            <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col-6">
                                <Tag className="p-mb-2" value="Piercing" icon={`pi pi-${hotmerDetail.stat.bl_piercing ? "check" : "times"}`} severity={hotmerDetail.stat.bl_piercing ? "info" : "warning"} />
                                <b>Chest</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_chest}</p>
                                <b>Eyes</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_eye_color}</p>
                                <b>Hair</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_hair}</p>
                            </div>
                            <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col-6">
                                <Tag className="p-mb-2" value="Tatoo" icon={`pi pi-${hotmerDetail.stat.bl_tatoo ? "check" : "times"}`} severity={hotmerDetail.stat.bl_tatoo ? "info" : "warning"} />
                                <b>Height</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_height}</p>
                                <b>Skin</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_skin_tone}</p>
                                <b>Weight</b>
                                <p>{hotmerDetail.stat.ds_name_type_of_weight}</p>
                            </div>
                        </div>
                    ) : (
                        <h3>Not found</h3>
                    )}
                </Fieldset>
            </>
        );
    };

    const Subscription = () => {
        const { ds_currency, vl_conversion_rate, vl_month1_price, vl_month3_price, vl_month6_price, vl_month12_price } = hotmerDetail.subscription_price;
        return (
            <>
                <Fieldset className="p-mb-2" legend={ds_currency} toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>1 month</b>
                            <p>
                                ${parseFloat(vl_conversion_rate * vl_month1_price).toFixed(2)} ({ds_currency})
                            </p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>3 months</b>
                            <p>
                                ${parseFloat(vl_conversion_rate * vl_month3_price).toFixed(2)} ({ds_currency})
                            </p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>6 months</b>
                            <p>
                                ${parseFloat(vl_conversion_rate * vl_month6_price).toFixed(2)} ({ds_currency})
                            </p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>12 months</b>
                            <p>
                                ${parseFloat(vl_conversion_rate * vl_month12_price).toFixed(2)} ({ds_currency})
                            </p>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset className="p-mb-2" legend="USD" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>1 month</b>
                            <p>${parseFloat(vl_month1_price).toFixed(2)} (USD)</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>3 months</b>
                            <p>${parseFloat(vl_month3_price).toFixed(2)} (USD)</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>6 months</b>
                            <p>${parseFloat(vl_month6_price).toFixed(2)} (USD)</p>
                        </div>
                        <div className="p-d-flex p-flex-column p-jc-center p-ai-center p-col p-shadow-6 p-m-2 p-p-2">
                            <b>12 months</b>
                            <p>${parseFloat(vl_month12_price).toFixed(2)} (USD)</p>
                        </div>
                    </div>
                </Fieldset>
            </>
        );
    };
    const Identity = () => {
        const [zoomFront, setZoomFront] = useState(1);
        const [wa, setWa] = useState("");
        const [zoomBack, setZoomBack] = useState(1);
        const [zoomSelfie, setZoomSelfie] = useState(1);
        const { full_name, ds_nick_name, ds_address, ds_country, ds_type_of_gender_name, id_status_profile_hotmer, ds_type_of_role_name, ds_instagram, ds_url_photo_id_front, ds_url_photo_id_reverse, ds_url_photo_id_holding, ds_phone_number } = hotmerDetail.identity;
        const waMe1 = `https://wa.me/+54${ds_phone_number}`;
        // const waMe1 = "https://wa.me/"+({ds_phone_number});
        /* setWa(`https://wa.me/${ds_phone_number}`);
         console.log("numerito argentino 1",wa);
         if (ds_country==="Argentina") {
         setWa(`https://wa.me/+54${ds_phone_number}`);
         console.log("numerito argentino 2",wa);
         } else {
             setWa(`https://wa.me/${ds_phone_number}`);
         console.log("numerito argentino 3",wa);
         };*/
        ///solo aplica si es whats app de argentina.
        //const waMe1 = `https://wa.me/+54${ds_phone_number}`;
        // const returnUrl = window.location.protocol + "//" + window.location.host + waMe1
        // console.log(`On easter we decorted ${eggCount}` easter eggs);

        return (
            <Card>
                <Fieldset className="p-mb-2" legend="Data" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col">
                            <div className="p-formgrid p-grid">
                                <p className="p-col-12 p-md-6">
                                    <b>Full Name: </b>
                                    {full_name}
                                </p>
                                <p className="p-col-12 p-md-6">
                                    <b>Status: </b>
                                    {status(id_status_profile_hotmer)}
                                </p>
                            </div>
                            <div className="p-formgrid p-grid">
                                <p className="p-col-12 p-md-6">
                                    <b>HotmyLink: </b>
                                    {ds_nick_name}
                                </p>
                                <p className="p-col-12 p-md-6">
                                    <b>WhatsApp: </b>
                                    <a href={waMe1} target="_blank" without rel="noreferrer">
                                        {ds_phone_number}
                                    </a>
                                </p>
                            </div>

                            <p>
                                <a href={`https://hotmy.app/${ds_nick_name}`} target="_blank" without rel="noreferrer">
                                    {`https://hotmy.app/${ds_nick_name}`}{" "}
                                </a>
                            </p>

                            <p>
                                <b>Instagram: </b>
                                <a href={`https://www.instagram.com/${ds_instagram}`} target="_blank" without rel="noreferrer">
                                    {`https://www.instagram.com/${ds_instagram}`}
                                </a>
                            </p>
                            {/* <p><b>Instagram: </b><a href={ds_instagram}</p> */}
                            <p>
                                <b>Address: </b>
                                {ds_address}
                            </p>
                            <p>
                                <b>Country: </b>
                                {ds_country}
                            </p>
                            <p>
                                <b>Genero: </b>
                                {ds_type_of_gender_name}
                            </p>
                            <p>
                                <b>Rol: </b>
                                {ds_type_of_role_name}
                            </p>
                        </div>
                        <div className="p-field p-col-12 p-mt-5">
                            <span className="p-float-label">
                                <InputTextarea disabled rows={5} cols={30} autoResize id="comments" type="text" value={""} />
                                <label htmlFor="comments">Comments</label>
                            </span>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset className="p-mb-2" legend="Attachment" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col-12 p-md-6">
                            <label>Zoom front</label>
                            <div className="p-col-6">
                                <InputNumber
                                    id="horizontal"
                                    value={zoomFront}
                                    onChange={(e) => setZoomFront(e.value)}
                                    min={0.5}
                                    showButtons
                                    buttonLayout="horizontal"
                                    step={0.25}
                                    decrementButtonClassName="p-button-danger"
                                    incrementButtonClassName="p-button-success"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    mode="decimal"
                                    minFractionDigits={0}
                                    maxFracionDigits={1}
                                />
                            </div>
                            <InnerImageZoom src={ds_url_photo_id_front} className="p-mt-2" zoomSrc={ds_url_photo_id_front} width="100%" display="block" zoomScale={zoomFront} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label>Zoom back</label>
                            <div className="p-col-6">
                                <InputNumber
                                    id="horizontal"
                                    value={zoomBack}
                                    onChange={(e) => setZoomBack(e.value)}
                                    min={0.5}
                                    showButtons
                                    buttonLayout="horizontal"
                                    step={0.25}
                                    decrementButtonClassName="p-button-danger"
                                    incrementButtonClassName="p-button-success"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    mode="decimal"
                                    minFractionDigits={0}
                                    maxFracionDigits={1}
                                />
                            </div>
                            <InnerImageZoom src={ds_url_photo_id_reverse} className="p-mt-2" zoomSrc={ds_url_photo_id_reverse} width="100%" display="block" zoomScale={1} />
                        </div>
                    </div>
                </Fieldset>
                <Fieldset className="p-mb-2" legend="Holding" toggleable>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div className="p-col">
                            <label>Zoom Selfie</label>
                            <div className="p-col-6">
                                <InputNumber
                                    style={{ width: "50%" }}
                                    id="horizontal"
                                    value={zoomSelfie}
                                    onChange={(e) => setZoomSelfie(e.value)}
                                    min={0.5}
                                    showButtons
                                    buttonLayout="horizontal"
                                    step={0.25}
                                    decrementButtonClassName="p-button-danger"
                                    incrementButtonClassName="p-button-success"
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    mode="decimal"
                                    minFractionDigits={0}
                                    maxFracionDigits={1}
                                />
                            </div>
                            <InnerImageZoom className="p-mt-2" src={ds_url_photo_id_holding} zoomSrc={ds_url_photo_id_holding} width="100%" display="block" zoomScale={2} />
                        </div>
                    </div>
                </Fieldset>
            </Card>
        );
    };

    const ViewContent = () => {
        const listFotos = hotmerDetail?.foto?.map((fotos) => <CardsContent idContentGroup={fotos.id_content_group} idContentImage={fotos.id_content_image} image={fotos.ds_url_thumbnail} bl_active={fotos.bl_active} location={fotos.dt_publish} ds_comment={fotos.ds_comment} />);

        const listVideos = hotmerDetail?.video?.map((videos) => <CardsContent idContentGroup={videos.id_content_group} idContentImage={videos.id_content_image} image={videos.ds_url_thumbnail} bl_active={videos.bl_active} location={videos.dt_publish} ds_comment={videos.ds_comment} />);

        return (
            <div>
                <h2 className="p-col-12">CONTENIDO HOTMER</h2>
                <h2 className="p-col-12">Photos</h2>

                <div className="image-grid">{listFotos}</div>

                {/* <Gallery 
                photos={pack?.content_pack?.images} />; */}
                <h2 className="p-col-12">Video Captura</h2>
                <div className="image-grid">{listVideos}</div>
            </div>
        );
    };

    // const numberWA = "+5491131500213";
    const URL_BASE_WA = process.env.REACT_APP_WS;

    const MessageCenter = () => {
        const { full_name, ds_nick_name, ds_phone_number } = hotmerDetail?.identity;
        const [loading, setLoading] = useState(false);

        // Estados para el botón "Recomendaciones"
        const [loadingRecommendation, setLoadingRecommendation] = useState(false);
        const [errorRecommendation, setErrorRecommendation] = useState(null);
        const [successRecommendation, setSuccessRecommendation] = useState(false);

        // Estados para el botón "Portada"
        const [loadingPortrait, setLoadingPortrait] = useState(false);
        const [errorPortrait, setErrorPortrait] = useState(null);
        const [successPortrait, setSuccessPortrait] = useState(false);

        // Estados para el botón "Diseno Personalizado"
        const [loadingPersonalDesing, setLoadingPersonalDesing] = useState(false);
        const [errorPersonalDesing, setErrorPersonalDesing] = useState(null);
        const [successPersonalDesing, setSuccessPersonalDesing] = useState(false);

        // Estados para el botón "Completar Perfil"
        const [loadingCompleteProfile, setLoadingCompleteProfile] = useState(false);
        const [errorCompleteProfile, setErrorCompleteProfile] = useState(null);
        const [successCompleteProfile, setSuccessCompleteProfile] = useState(false);

        // Estados para el botón "Revision de Estado"
        const [loadingStatusCheck, setLoadingStatusCheck] = useState(false);
        const [errorStatusCheck, setErrorStatusCheck] = useState(null);
        const [successStatusCheck, setSuccessStatusCheck] = useState(false);

        // Estados para el botón "Bienvenida luego de Aprobar con imagenes"
        const [loadingWelcomeAfterApprove, setLoadingWelcomeAfterApprove] = useState(false);
        const [errorWelcomeAfterApprove, setErrorWelcomeAfterApprove] = useState(null);
        const [successWelcomeAfterApprove, setSuccessWelcomeAfterApprove] = useState(false);

        // Estados para el botón "Perfil Aprobado"
        const [loadingApprove, setLoadingApprove] = useState(false);
        const [errorApprove, setErrorApprove] = useState(null);
        const [successApprove, setSuccessApprove] = useState(false);

        // Estados para el botón "Perfil Rechazado"
        const [loadingReject, setLoadingReject] = useState(false);
        const [errorReject, setErrorReject] = useState(null);
        const [successReject, setSuccessReject] = useState(false);

        // Estados para el botón "venta subscripcion"
        const [loadingSellSubscription, setLoadingSellSubscription] = useState(false);
        const [errorSellSubscription, setErrorSellSubscription] = useState(null);
        const [successSellSubscription, setSuccessSellSubscription] = useState(false);

        // Estados para el botón "venta pack"
        const [loadingSellPack, setLoadingSellPack] = useState(false);
        const [errorSellPack, setErrorSellPack] = useState(null);
        const [successSellPack, setSuccessSellPack] = useState(false);

        // Estados para el botón "venta Tip""
        const [loadingSellTip, setLoadingSellTip] = useState(false);
        const [errorSellTip, setErrorSellTip] = useState(null);
        const [successSellTip, setSuccessSellTip] = useState(false);

        const [loadingRememberUploadContent, setLoadingRememberUploadContent] = useState(false);
        const [errorRememberUploadContent, setErrorRememberUploadContent] = useState(null);
        const [successRememberUploadContent, setSuccessRememberUploadContent] = useState(false);

        const [loadingLastChanceUploadContent, setLoadingLastChanceUploadContent] = useState(false);
        const [errorLastChanceUploadContent, setErrorLastChanceUploadContent] = useState(null);
        const [successLastChanceUploadContent, setSuccessLastChanceUploadContent] = useState(false);

        const approveCreator = async (endpoint, ds_phone_number, full_name, ds_nick_name, setLoading, setError, setSuccess) => {
            const url = `${URL_BASE_WA}${endpoint}`;
            const data = {
                number: ds_phone_number,
                name: full_name,
                creador: ds_nick_name,
            };

            setLoading(true);
            setError(null);
            setSuccess(false);

            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Headers": "Content-Type",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                    },
                };
                const response = await axios.post(url, JSON.stringify(data), config);
                // .then((response) => {
                if (response?.status !== 200) {
                    throw new Error(`HTTP error! status: ${response?.status}`);
                }
                // console.log("Success:", responseService);
                setSuccess(true);
                console.log(response.data);
                //   });
                // const response = await fetch(url, {
                //     method: "POST",
                //     headers: {
                //         "Content-Type": "application/json",
                //         "Referrer-Policy": "no-referrer"
                //     },
                //     body: JSON.stringify(data),
                // });

                // const responseService = await response.json();
                // if (response.status !== 200) {
                //     throw new Error(`HTTP error! status: ${response.status}`);
                // }
                // console.log("Success:", responseService);
                // setSuccess(true);
            } catch (error) {
                console.error("Error:", error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
            return;
        };

        return (
            <div style={{ margin: "15px" }}>
                <Fieldset legend="Envio mensajes seguimiento" style={{ margin: "15px" }}>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div>
                            <Button id={1} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("portrait", ds_phone_number, full_name, ds_nick_name, setLoadingPortrait, setErrorPortrait, setSuccessPortrait)} disabled={loading}>
                                Faltante Portada
                                {loadingPortrait && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successPortrait ? <span className="pi pi-check" color="#ffffff" style={{ marginLeft: "5px", padding: "0px 8px" }}></span> : ""}
                            </Button>
                            {errorPortrait && <div style={{ color: "red" }}>Error: {errorPortrait}</div>}
                        </div>
                        <div>
                            <Button id={2} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("recommendations", ds_phone_number, full_name, ds_nick_name, setLoadingRecommendation, setErrorRecommendation, setSuccessRecommendation)} disabled={loading}>
                                Recomendaciones
                                {loadingRecommendation && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successRecommendation ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorRecommendation && <div style={{ color: "red" }}>Error: {errorRecommendation}</div>}
                        </div>
                        <div>
                            <Button id={3} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("personaldesign", ds_phone_number, full_name, ds_nick_name, setLoadingPersonalDesing, setErrorPersonalDesing, setSuccessPersonalDesing)} disabled={loading}>
                                Diseño personalizado
                                {loadingPersonalDesing && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successPersonalDesing ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorPersonalDesing && <div style={{ color: "red" }}>Error: {errorPersonalDesing}</div>}
                        </div>
                        <div>
                            <Button id={3} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("incompleteprofile", ds_phone_number, full_name, ds_nick_name, setLoadingCompleteProfile, setErrorCompleteProfile, setSuccessCompleteProfile)} disabled={loading}>
                                Completar Perfil
                                {loadingCompleteProfile && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successCompleteProfile ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorCompleteProfile && <div style={{ color: "red" }}>Error: {errorCompleteProfile}</div>}
                        </div>
                        <div>
                            <Button id={32} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("statusCheck", ds_phone_number, full_name, ds_nick_name, setLoadingStatusCheck, setErrorStatusCheck, setSuccessStatusCheck)} disabled={loading}>
                                Revision de Estado
                                {loadingStatusCheck && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successStatusCheck ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorStatusCheck && <div style={{ color: "red" }}>Error: {errorStatusCheck}</div>}
                        </div>

                        <div>
                            <Button id={32} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("welcomeafterapprove", ds_phone_number, full_name, ds_nick_name, setLoadingWelcomeAfterApprove, setErrorWelcomeAfterApprove, setSuccessWelcomeAfterApprove)} disabled={loading}>
                                Bienvenida Luego de Aprobar
                                {loadingWelcomeAfterApprove && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successWelcomeAfterApprove ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorWelcomeAfterApprove && <div style={{ color: "red" }}>Error: {errorWelcomeAfterApprove}</div>}
                        </div>


                        <div>
                            <Button id={32} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("rememberupload", ds_phone_number, full_name, ds_nick_name, setLoadingRememberUploadContent, setErrorRememberUploadContent, setSuccessRememberUploadContent)} disabled={loading}>
                                Recordar Subir Contenido
                                {loadingRememberUploadContent && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successRememberUploadContent ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorRememberUploadContent && <div style={{ color: "red" }}>Error: {errorRememberUploadContent}</div>}
                        </div>

                        <div>
                            <Button id={32} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("lastchancetouploadcontent", ds_phone_number, full_name, ds_nick_name, setLoadingLastChanceUploadContent, setErrorLastChanceUploadContent, setSuccessLastChanceUploadContent)} disabled={loading}>
                                Ultimo aviso 🚨
                                {loadingLastChanceUploadContent && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successLastChanceUploadContent ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorLastChanceUploadContent && <div style={{ color: "red" }}>Error: {errorLastChanceUploadContent}</div>}
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Envio mensajes Aprobaciones y Rechazos de Perfil" style={{ margin: "15px" }}>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div>
                            <Button id={4} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("approvecreator", ds_phone_number, full_name, ds_nick_name, setLoadingApprove, setErrorApprove, setSuccessApprove)} disabled={loading}>
                                Perfil Aprobado
                                {loadingApprove && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successApprove ? <span className="pi pi-check" color="#ffffff" style={{ marginLeft: "5px", padding: "0px 8px" }}></span> : ""}
                            </Button>
                            {errorApprove && <div style={{ color: "red" }}>Error: {errorApprove}</div>}
                        </div>
                        <div>
                            <Button id={5} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("refusecreator", ds_phone_number, full_name, ds_nick_name, setLoadingReject, setErrorReject, setSuccessReject)} disabled={loading}>
                                Perfil Rechazado
                                {loadingReject && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successReject ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorReject && <div style={{ color: "red" }}>Error: {errorReject}</div>}
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Envio mensajes venta" style={{ margin: "15px" }}>
                    <div className="p-d-flex p-fluid p-formgrid p-grid">
                        <div>
                            <Button id={6} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("sellsubscription", ds_phone_number, full_name, ds_nick_name, setLoadingSellSubscription, setErrorSellSubscription, setSuccessSellSubscription)} disabled={loading}>
                                Vendiste Subscripcion
                                {loadingSellSubscription && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successSellSubscription ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorSellSubscription && <div style={{ color: "red" }}>Error: {errorSellSubscription}</div>}
                        </div>
                        <div>
                            <Button id={7} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("sellpack", ds_phone_number, full_name, ds_nick_name, setLoadingSellPack, setErrorSellPack, setSuccessSellPack)} disabled={loading}>
                                Vendiste Pack
                                {loadingSellPack && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successSellPack ? <span className="pi pi-check" color="#ffffff" style={{ marginLeft: "5px", padding: "0px 8px" }}></span> : ""}
                            </Button>
                            {errorSellPack && <div style={{ color: "red" }}>Error: {errorSellPack}</div>}
                        </div>
                        <div>
                            <Button id={8} style={{ margin: "10px 20px", width: "auto" }} onClick={() => approveCreator("selltip", ds_phone_number, full_name, ds_nick_name, setLoadingSellTip, setErrorSellTip, setSuccessSellTip)} disabled={loading}>
                                Vendiste Tip
                                {loadingSellTip && (
                                    <div className="p-d-flex p-jc-center">
                                        <ProgressSpinner style={{ with: "15px", height: "15px" }} />
                                    </div>
                                )}
                                {successSellTip ? <span className="pi pi-check"></span> : ""}
                            </Button>
                            {errorSellTip && <div style={{ color: "red" }}>Error: {errorSellTip}</div>}
                        </div>
                    </div>
                </Fieldset>
            </div>
        );
    };

    const items = [
        //pi-tag
        //pi-id-card
        { label: "Summary", icon: "pi pi-info-circle", component: <Summary />, position: 0 },
        { label: "Finantial", icon: "pi pi-wallet", component: <Statistics />, position: 1 },
        { label: "Stat", icon: "pi pi-eye", component: <Stat />, position: 2 },
        { label: "Subscription", icon: "pi pi-bell", component: <Subscription />, position: 3 },
        { label: "Identity", icon: "pi pi-eye", component: <Identity />, position: 4 },
        { label: "Content", icon: "pi pi-images", component: <ViewContent />, position: 5 },
        { label: "Message", icon: "pi pi-send", component: <MessageCenter />, position: 6 },
    ];
    const [activeItem, setActiveItem] = useState(0);
    return (
        <>
            <Dialog className="p-col-12 p-md-8" closable={false} visible={show} onHide={closeDialog} position="center" draggable={false} resizable={false} baseZIndex={1000} footer={renderFooter}>
                <Hedaer />
                {hotmerDetail ? (
                    <div className="p-fluid p-formgrid p-grid" style={{ borderBottom: "1px solid #d0d0d0" }}>
                        <Fieldset legend="Profile" toggleable className="p-d-lg-none p-p-1 p-col-12">
                            {hotmerDetail.personal_data ? (
                                <>
                                    <div className="p-d-flex p-flex-column p-ai-center p-mb-2">
                                        <Avatar alt="/assets/images/perfil.png" imageAlt="/assets/images/perfil.png" image={hotmerDetail.personal_data.ds_profile_photo_path} style={{ width: 60, height: 60 }} shape="circle" />
                                        <Rating value={hotmerDetail.personal_data.vl_score / 2} readOnly cancel={false} stars={5} />
                                        <b>({hotmerDetail.personal_data.vl_score})</b>
                                        <span className={`customer-badge status-${hotmerDetail.personal_data.bl_status ? "qualified" : "unqualified"}`}>{hotmerDetail.personal_data.bl_status ? "QUALIFIED" : "UNQUALIFIED"}</span>
                                        <Avatar className="p-p-1 p-mb-2" alt="/assets/images/portada.png" imageAlt="/assets/images/portada.png" image={hotmerDetail.personal_data.ds_profile_photo_thumbnail_path} style={{ width: "100%", height: 100 }} />
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid p-text-center">
                                        <span className="p-col p-mb-3">
                                            <b>Nationality:</b> {hotmerDetail.personal_data.ds_country}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Mail:</b> {hotmerDetail.personal_data.ds_mail}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Birth Date:</b> {moment(hotmerDetail.personal_data.dt_birth).format("YYYY/MM/DD")}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Created Date:</b> {moment(hotmerDetail.personal_data.dt_created_at).format("YYYY/MM/DD")}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Referral Code:</b> {hotmerDetail.personal_data.ds_referral_code}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Referral By:</b> {hotmerDetail.personal_data.referral_by}
                                        </span>
                                        <span className="p-col p-mb-3">
                                            <b>Commision %:</b> {hotmerDetail.personal_data.commision}
                                        </span>
                                    </div>
                                </>
                            ) : (
                                <h3>Personal data not found</h3>
                            )}
                        </Fieldset>
                        <div className="p-d-none p-d-lg-block p-p-3 p-col-3" style={{ borderRight: "1px solid #d0d0d0" }}>
                            {hotmerDetail.personal_data ? (
                                <>
                                    <div className="p-d-flex p-jc-around p-ai-center p-mb-2">
                                        <Avatar alt="/assets/images/perfil.png" image={hotmerDetail.personal_data.ds_profile_photo_path} style={{ width: 40, height: 40 }} shape="circle" />
                                        <Rating value={hotmerDetail.personal_data.vl_score / 2} readOnly cancel={false} stars={5} />
                                        <b>({hotmerDetail.personal_data.vl_score})</b>
                                    </div>
                                    <Avatar className="p-p-1 p-mb-2" alt="/assets/images/portada.png" image={hotmerDetail.personal_data.ds_profile_photo_thumbnail_path} style={{ width: "100%", height: 100 }} />
                                    <div className="p-d-flex p-jc-between p-ai-center p-mb-4">
                                        <div>
                                            <b>Nationality:</b>
                                            <p>{hotmerDetail.personal_data.ds_country}</p>
                                        </div>
                                        <div>
                                            <b>Status:</b>
                                            <p className={`customer-badge status-${hotmerDetail.personal_data.bl_status ? "qualified" : "unqualified"}`}>{hotmerDetail.personal_data.bl_status ? "QUALIFIED" : "UNQUALIFIED"}</p>
                                        </div>
                                    </div>
                                    <b>Mail:</b>
                                    <p>{hotmerDetail.personal_data.ds_mail}</p>
                                    <b>Birth Date:</b>
                                    <p>{moment(hotmerDetail.personal_data.dt_birth).format("YYYY/MM/DD")}</p>
                                    <b>Created Date:</b>
                                    <p>{moment(hotmerDetail.personal_data.dt_created_at).format("YYYY/MM/DD")}</p>
                                    <b>Referral Code:</b>
                                    <p>{hotmerDetail.personal_data.ds_referral_code}</p>
                                    <b>Referral By:</b>
                                    <p>{hotmerDetail.personal_data.referral_by}</p>
                                    <b>Commision %:</b>
                                    <p>{hotmerDetail.personal_data.referral_by}</p>
                                </>
                            ) : (
                                <h3>Personal data not found</h3>
                            )}
                        </div>
                        <div className="p-p-3 p-col-12 p-lg-9">
                            <TabMenu model={items} activeItem={items[activeItem]} onTabChange={(e) => setActiveItem(e.value.position)} />
                            <div className="p-mt-3">{items[activeItem].component}</div>
                        </div>
                    </div>
                ) : (
                    <h3>Not hotmer detail found</h3>
                )}
            </Dialog>
        </>
    );
};

export default DialogHotmerDetails;
